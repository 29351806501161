import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {ChatBotDeliverablesBlock} from './ChatBotDeliverablesBlock'
import classes from './styles.module.scss'
import {ContactInfo} from '../../../api/model/ContactInfo'
import {FamilyShareTreeView} from './tree_view/FamilyShareTreeView'

interface ChatBotDeliverablesSectionProps {
    contactInfo: ContactInfo
}

const ChatBotDeliverablesSection: React.FC<ChatBotDeliverablesSectionProps> = ({contactInfo}) => {

  const {
    username,
    familyPedigreeQuestionnaireLink,
    familyTreeLink,
    readinessRulerLink,
    readinessRulerXLSXLink,
    familyPedigreeQuestionnaireXLSXLink,
    inviteDTOS
  } = contactInfo

  const openPDFHandler = (e, viewUrl: string) => {
    e.preventDefault()
    if (viewUrl) {
      try {
        fetch(viewUrl, {method: 'GET'}).then(res => {
          if (res.ok) {
            return res.blob()
          }
        }).then(res => {
          const fileURL = URL.createObjectURL(res)
          window.open(fileURL, '_blank')
        })
      } catch (e) {
        console.log(e)
      }
    }
  }

  return (
    <div className={classes.deliverablesSectionWrapper}>
      <div className={classes.sectionTitle}>
            Chatbot deliverables
      </div>
      <ChatBotDeliverablesBlock
        title={'Readiness Ruler'}
        viewUrl={readinessRulerLink}
        excelLink={readinessRulerXLSXLink}
        openPDFHandler={openPDFHandler}
        username={username}
        hasDownloadExcelButton={true}
      />
      <ChatBotDeliverablesBlock
        title={'Family Pedigree Questionnaire'}
        viewUrl={familyPedigreeQuestionnaireLink}
        excelLink={familyPedigreeQuestionnaireXLSXLink}
        openPDFHandler={openPDFHandler}
        username={username}
        hasDownloadExcelButton={true}
      />
      <ChatBotDeliverablesBlock
        title={'Family Tree'}
        viewUrl={familyTreeLink}
        openPDFHandler={openPDFHandler}
        hasDownloadExcelButton={false}
      /><br></br>
      {inviteDTOS.length !== 0 && <div>
        <div>Family Sharing Info</div>
        <br></br>
        <FamilyShareTreeView username={username} data={inviteDTOS}>
        </FamilyShareTreeView>
      </div>}
    </div>
  )
}

const mapStateToProps = state => ({
  contactInfo: state.contactInfo
})

export default withRouter(connect(mapStateToProps)(ChatBotDeliverablesSection))
