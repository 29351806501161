import React, { FC, MouseEvent } from 'react'
import './custom-node.scss'

interface NodeData {
    name: string;
    current: boolean;
    link: string;
}

interface MyNodeProps {
    nodeData: NodeData;
}

export const CustomNode: FC<MyNodeProps> = ({ nodeData }) => {
  const selectNode = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    window.open(nodeData.link, '_blank')
  }

  return (
    <div onClick={selectNode}>
      <div className={`username ${nodeData.current ? 'current' : ''}`}>{nodeData.name}</div>
    </div>
  )
}
